.splitter-layout {
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.splitter-layout .layout-pane {
  position: relative;
  flex: 0 0 auto;
  overflow: auto;
}

.layout-splitter { 
  position: relative; 
}

.layout-splitter:after {
       -webkit-transform: translate(-50%, -50%) rotate(90deg);
       transform: translate(-50%, -50%) rotate(90deg);
       content: "";
       display: block;
       width: 4px;
       height: 4px;
       background-color: #979797;
       box-shadow: #979797 -6px 0px 0px, #979797 6px 0px 0px;
       border-radius: 8px;
       position: absolute;
       top: 50%;
       left: 50%;
}

.splitter-layout .layout-pane.layout-pane-primary {
  flex: 1 1 auto;
}

.splitter-layout > .layout-splitter {
  flex: 0 0 auto;
  width: 10px;
  height: 100%;
  cursor: col-resize;
  background-color: #EDEDED;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 2px 1px;
}

.splitter-layout .layout-splitter:hover {
  background-color: #bbb;
}

.splitter-layout.layout-changing {
  cursor: col-resize;
}

.splitter-layout.layout-changing > .layout-splitter {
  background-color: #aaa;
}

.splitter-layout.splitter-layout-vertical {
  flex-direction: column;
}

.splitter-layout.splitter-layout-vertical.layout-changing {
  cursor: row-resize;
}

.splitter-layout.splitter-layout-vertical > .layout-splitter {
  width: 100%;
  height: 4px;
  cursor: row-resize;
}
