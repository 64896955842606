.CodeMirrorDMLPreview .CodeMirror {
    height: 464px;
    padding-top: 15px
}

.btn-outline-DMLPreview:hover {
    color: #fff;
    background-color: #1F76D3;
    border-color: #1F76D3;
}

.btn-outline-DMLPreview {
    color: #1F76D3;
    background-color: transparent;
    background-image: none;
    border-color: #1F76D3;
    font-size: 14px;
    font-family: 'Roboto', Tahoma, Geneva, Verdana, sans-serif;
}