/*
http://lesscss.org/ dark theme
Ported to CodeMirror by Peter Kroon
*/
.cm-s-lesser-dark {
  line-height: 1.3em;
}
.cm-s-lesser-dark.CodeMirror { background: #262626; color: #EBEFE7; text-shadow: 0 -1px 1px #262626; }
.cm-s-lesser-dark div.CodeMirror-selected { background: #45443B; } /* 33322B*/
.cm-s-lesser-dark .CodeMirror-line::selection, .cm-s-lesser-dark .CodeMirror-line > span::selection, .cm-s-lesser-dark .CodeMirror-line > span > span::selection { background: rgba(69, 68, 59, .99); }
.cm-s-lesser-dark .CodeMirror-line::-moz-selection, .cm-s-lesser-dark .CodeMirror-line > span::-moz-selection, .cm-s-lesser-dark .CodeMirror-line > span > span::-moz-selection { background: rgba(69, 68, 59, .99); }
.cm-s-lesser-dark .CodeMirror-cursor { border-left: 1px solid white; }
.cm-s-lesser-dark pre { padding: 0 8px; }/*editable code holder*/

.cm-s-lesser-dark.CodeMirror span.CodeMirror-matchingbracket { color: #7EFC7E; }/*65FC65*/

.cm-s-lesser-dark .CodeMirror-gutters { background: #262626; border: none; width: 32px; text-align: center; }
.cm-s-lesser-dark .CodeMirror-guttermarker { color: #599eff; }
.cm-s-lesser-dark .CodeMirror-guttermarker-subtle { color: #777; }
.cm-s-lesser-dark .CodeMirror-linenumber { color: #787878; }

.cm-s-lesser-dark span.cm-header { color: #a0a; }
.cm-s-lesser-dark span.cm-quote { color: #8ABF9D; }
.cm-s-lesser-dark span.cm-keyword { color: #26CFF9; }
.cm-s-lesser-dark span.cm-atom { color: #C2B470; }
.cm-s-lesser-dark span.cm-number { color: #B35E4D; }
.cm-s-lesser-dark span.cm-def { color: white; }
.cm-s-lesser-dark span.cm-variable { color:#D9BF8C; }
.cm-s-lesser-dark span.cm-variable-2 { color: #669199; }
.cm-s-lesser-dark span.cm-variable-3, .cm-s-lesser-dark span.cm-type { color: white; }
.cm-s-lesser-dark span.cm-property { color: #8ABF9D; }
.cm-s-lesser-dark span.cm-operator { color: #8ABF9D; }
.cm-s-lesser-dark span.cm-comment { color: #666; }
.cm-s-lesser-dark span.cm-string { color: #8ABF9D; }
.cm-s-lesser-dark span.cm-string-2 { color: #f50; }
.cm-s-lesser-dark span.cm-meta { color: #738C73; }
.cm-s-lesser-dark span.cm-qualifier { color: #555; }
.cm-s-lesser-dark span.cm-builtin { color: #ff9e59; }
.cm-s-lesser-dark span.cm-bracket { color: #EBEFE7; }
.cm-s-lesser-dark span.cm-tag { color: #26CFF9; }
.cm-s-lesser-dark span.cm-attribute { color: #26CFF9; }
.cm-s-lesser-dark span.cm-hr { color: #999; }
.cm-s-lesser-dark span.cm-link { color: #26CFF9; }
.cm-s-lesser-dark span.cm-error { color: #9d1e15; }

.cm-s-lesser-dark .CodeMirror-activeline-background { background: #3C3A3A; }
.cm-s-lesser-dark .CodeMirror-matchingbracket { outline:1px solid grey; color:white !important; }
